<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div
        v-show="hasImport == true"
        class="importDiv"
        style="display:flex;background:#E4EBF1;width:100%;"
      >
        导入结果：共导入{{ sumcount }}条数据，其中{{
          errcount
        }}条错误。<Checkbox
          v-model="single"
          style="color:#314558;margin-left:30px;width:200px;"
          @on-change="showfalseData"
          >显示错误内容</Checkbox
        >
      </div>
      <div class="main-body">
        <!-- <div class="select-one">
            <Select v-model="devSearchList" clearable style="width:110px">
                <Option v-for="item in devSearchLists" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <Input prefix="ios-search"  v-model="searchText" placeholder="请输入搜索内容" style="width: 280px;margin-left:-5px;" />
        </div> -->
        <vxe-toolbar>
          <!-- <div class="btn-area">
          <div class="btn-block" @click="downloadTemplete">
            <img src="/static/images/aoya/moban.png" alt="" >
            下载模板
          </div>
          <div class="btn-block" @click="inport">
            <img src="/static/images/aoya/Inport.png" alt="">
            导入Excel
          </div>
          <div class="btn-block" @click="delect">
            <i class="icon iconlajitong"></i>
            删除
          </div>
        </div> -->
          <template v-slot:buttons>
            <vxe-button
              @click="downloadTemplete"
              style="border:none;background:none;padding:0px;"
            >
              <div style="display:flex;align-items:center;height:30px;">
                <!-- <img src="/static/images/aoya/moban.png" alt="" style="width:20px;height:20px;margin-top:5px;"> -->
                <i class="icon1 iconicon-xiazai"></i>
                下载模板
              </div>
            </vxe-button>
            <vxe-button
              @click="inport"
              style="border:none;background:none;padding:0px;"
            >
              <Upload
                ref="upload"
                :action="uploadExclUrl"
                name="file"
                :show-upload-list="false"
                :on-format-error="handleFormatError"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :on-error="handleError"
                :format="['xlsx', 'xls']"
              >
                <div style="display:flex;align-items:center;height:30px;">
                  <i class="icon1 icondaochu-copy"></i>
                  导入Excel
                </div>
                <!-- <Button type="text" style="border: none;border-radius: 4px;padding:0px;height:30px;line-height:30px;">导入Excel</Button> -->
              </Upload>
            </vxe-button>
            <vxe-button
              @click="delect"
              style="border:none;background:none;padding:0px;"
            >
              <div style="display:flex;align-items:center;height:30px;">
                <i class="icon1 iconlajitong"></i>
                删除
              </div>
            </vxe-button>
            <!-- <vxe-button @click="$refs.xTable.remove(tableData[1])">删除第2行</vxe-button>
            <vxe-button @click="$refs.xTable.removeSelecteds()">删除选中</vxe-button>
            <vxe-button @click="getRemoveEvent">获取删除</vxe-button>
            <vxe-button @click="getSelectionEvent">获取选中</vxe-button> -->
          </template>
        </vxe-toolbar>

        <div class="table-area">
          <!-- <Table class="devTable" border ref="selection" :loading="loading" :columns="theadDate" :data="tableDate" ></Table> -->
          <vxe-table
            class="devTable"
            ref="xTable"
            border
            show-overflow
            :edit-rules="validRules"
            :row-class-name="rowClassName"
            :cell-class-name="cellClassName"
            :data="tableData"
            :edit-config="{ trigger: 'click', mode: 'cell', showStatus: true }"
          >
            <vxe-table-column type="selection" width="60"></vxe-table-column>
            <vxe-table-column
              type="index"
              title="序号"
              width="60"
            ></vxe-table-column>
            <vxe-table-column
              class-name="errorMsg"
              field="errmsg"
              title="错误原因"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydNumber"
              title="设备编号"
              :edit-render="{
                name: 'input',
                events: { blur: blurEvent, focus: focusEvent }
              }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydType"
              title="设备类型"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydWorkshop"
              title="所属车间"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydWeftframenum"
              title="纬纱架位数"
              :edit-render="{ name: 'input', maxlength: '1' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydEconomicSpeed"
              title="经济转速"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydFactoryDate"
              title="入厂日期"
              :edit-render="{ name: 'input' }"
              width="100"
            >
              <template v-slot:edit="{ row }">
                <input
                  type="date"
                  v-model="row.aydFactoryDate"
                  class="custom-input"
                />
              </template>
              <!-- <template v-slot="{ row }">{{ row.factoryDate }}</template> -->
            </vxe-table-column>
            <vxe-table-column
              field="aydManufacturer"
              title="生产厂家"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydGearnum"
              title="机织棕框档数"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydHeadracknum"
              title="盘头架位数"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydLightTransport"
              title="是否光胶输送"
              :edit-render="{
                name: 'select',
                options: [
                  { label: '是', value: '1' },
                  { label: '否', value: '2' }
                ]
              }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydLockrand"
              title="是否锁边"
              :edit-render="{
                name: 'select',
                options: [
                  { label: '是', value: '1' },
                  { label: '否', value: '2' }
                ]
              }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydFactorynum"
              title="工厂编号"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydDaliyArtificial"
              title="每日人工产能"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydDaliyMechanical"
              title="每日机器产能"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydLaborEfficiency"
              title="标准人工效率"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydMachineLoad "
              title="标准机器负荷"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydWageRate"
              title="标准工资率"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydStandardfeeRate"
              title="标准制费分摊率"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydRemark"
              title="备注"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydFixationFeerate"
              title="标准固定制费分摊率"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydEffectiveDays"
              title="清场合格证有效天数"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <vxe-table-column
              field="aydDeptnum"
              title="部门编号"
              :edit-render="{ name: 'input' }"
              width="100"
            ></vxe-table-column>
            <vxe-table-column
              field="aydAutomaticWarehouse"
              title="自动领料仓库"
              :edit-render="{ name: 'input' }"
              width="150"
            ></vxe-table-column>
            <!-- <vxe-table-column title="操作">
                <template v-slot="{ row }">
                  <vxe-button @click="$refs.xTable.revertData(row)">还原</vxe-button>
                </template>
              </vxe-table-column> -->
          </vxe-table>
        </div>
        <div class="bottom" style="margin-top:30px;padding-left:30px;">
          <div class="btn-area">
            <Button
              style="border:1px solid #DFE3E8;background:white;"
              @click="cancle"
              >取消</Button
            >

            <Button
              v-show="errcount != 0"
              style="background:#DFE3E8;margin-left:20px;color:#B1B9BE;"
              @click="saveDev"
              :disabled="true"
              >保存</Button
            >
            <Button v-show="errcount == 0" type="primary" @click="saveDev"
              >保存</Button
            >
          </div>
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表格数据校验
      validRules: {
        aydWeftframenum: [{ max: 1, message: "最大长度为1位" }]
      },
      single: false,
      hasImport: false,
      time: "",
      uploadExclUrl: "",
      dialoLead: "",
      workData: "",
      selectOption: [
        {
          label: "是",
          value: "1"
        },
        {
          label: "否",
          value: "0"
        }
      ],
      selectOption1: [
        {
          label: "是",
          value: "1"
        },
        {
          label: "否",
          value: "0"
        }
      ],
      // 顶部筛选
      devSearchLists: [
        {
          value: "0",
          label: "设备型号"
        },
        {
          value: "1",
          label: "设备类型"
        },
        {
          value: "2",
          label: "生产厂家"
        }
      ],
      devSearchList: "", // 顶部第一个select选择的东西
      searchText: "", // 顶部搜索内容
      theadDate: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "序号",
          key: "num",
          width: "100px"
        },
        {
          title: "错误原因",
          key: "",
          width: "100px"
        },
        {
          title: "设备编号",
          key: "",
          width: "100px"
        },
        {
          title: "设备类型",
          key: "",
          width: "100px"
        },
        {
          title: "所属车间",
          key: "",
          width: "100px"
        },
        {
          title: "纬纱架位数",
          key: "",
          width: "100px"
        },
        {
          title: "经济转速",
          key: "",
          width: "100px"
        },
        {
          title: "入厂日期",
          key: "",
          width: "100px"
        },
        {
          title: "生产厂家",
          key: "",
          width: "100px"
        },
        {
          title: "最大宽度",
          key: "",
          width: "100px"
        },
        {
          title: "盘头架位数",
          key: "",
          width: "100px"
        },
        {
          title: "是否光胶输送",
          key: "",
          width: "100px"
        },
        {
          title: "是否锁边",
          key: "",
          width: "100px"
        },
        {
          title: "工厂编号",
          key: "",
          width: "100px"
        },
        {
          title: "每日人工产能",
          key: "",
          width: "100px"
        },
        {
          title: "每日机器产能",
          key: "",
          width: "100px"
        },
        {
          title: "标准人工效率",
          key: "",
          width: "100px"
        },
        {
          title: "标准机器负荷",
          key: "",
          width: "100px"
        },
        {
          title: "标准工资率",
          key: "",
          width: "100px"
        },
        {
          title: "标准制费分摊率",
          key: "",
          width: "100px"
        },
        {
          title: "备注",
          key: "",
          width: "100px"
        },
        {
          title: "标准固定制费分摊率",
          key: "",
          width: "100px"
        },
        {
          title: "清场合格证有效天数",
          key: "",
          width: "100px"
        },
        {
          title: "部门",
          key: "",
          width: "100px"
        },
        {
          title: "自动领料仓库",
          key: "",
          width: "500px"
        }
      ],
      finalTableData: [], // 最后始的数据
      tableData: [],
      errTableData: [], // 导入后存放错误设备信息
      loading: false,
      errcount: 0, // 导入的错误数据
      sumcount: 0, // 导入的总数据

      curAydNumber: "" // 当前编辑表格的原始数据
    };
  },
  methods: {
    focusEvent({
      row,
      rowIndex,
      $rowIndex,
      column,
      columnIndex,
      $columnIndex,
      cell
    }) {
      this.curAydNumber = row.aydNumber;
    },
    // 设备编号修改 失去焦点事件
    blurEvent({ column, row }, event) {
      if (column.model.update === true) {
        let hasExcited = false;
        // 判断现在修改的设备编号内容存不存在
        for (var i = 0; i < this.tableData.length; i++) {
          if (column.model.value === this.tableData[i].aydNumber) {
            hasExcited = true;
            if (this.tableData[i].errcode === 0) {
              this.tableData[i].errcode = 1;
              this.tableData[i].errmsg = "设备编号在仓库里已存在";
              this.errTableData.push(this.tableData[i]);
            }
            // break;
          }
        }
        // 如果当前修改的值本身是错误的 列表已存在 把已存在数据改为正常
        for (var k = 0; k < this.tableData.length; k++) {
          if (
            this.tableData[k].aydNumber === this.curAydNumber &&
            this.tableData[k].errcode === 1
          ) {
            this.tableData[k].errcode = 0;
            this.tableData[k].errmsg = "";
            if (this.errTableData.length > 0) {
              for (var l = 0; l < this.errTableData.length; l++) {
                if (
                  this.tableData[k].aydNumber === this.errTableData[l].aydNumber
                ) {
                  this.errTableData.splice(l, 1);
                }
              }
            }
          }
        }
        if (hasExcited === true) {
          // 如果已经存在
          if (row.errcode === 0) {
            // 如果之前修改之前是正确的 改成错误
            row.errcode = 1;
            this.errTableData.push(row);
            this.finalTableData = this.tableData;
            this.$Message.error("设备编号已存在！");
            row.errmsg = "设备编号在仓库里已存在";
          } else {
            this.$Message.error("设备编号已存在！");
          }
          this.finalTableData = this.tableData;
        } else {
          // 如果不存在
          this.axios({
            url: "/iext/back/device/DeviceController/deviceCodeIsExiest",
            method: "get",
            params: {
              deviceCode: column.model.value
            }
          })
            .then((res) => {
              if (res.data.success === 1) {
                row.errcode = 0;
                row.errmsg = "";
                this.$Message.success("修改成功！");
                // 如果修改成功需要把这条数据从错误表中去掉 加入到正常显示的数组中
                if (this.errTableData.length > 0) {
                  for (var k = 0; k < this.errTableData.length; k++) {
                    if (row.aydNumber === this.errTableData[k].aydNumber) {
                      this.errTableData.splice(k, 1);
                    }
                  }
                }
                this.finalTableData = this.tableData;
                this.errcount = this.errTableData.length;
                this.sumcount = this.finalTableData.length;
              } else {
                row.errcode = 1;
                this.errTableData.push(row);
                this.finalTableData = this.tableData;
                this.$Message.error(res.data.msg);
                row.errmsg = "设备编号在仓库里已存在";

                this.errcount = this.errTableData.length;
                this.sumcount = this.finalTableData.length;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      this.errcount = this.errTableData.length;
      this.sumcount = this.finalTableData.length;
    },
    cancle() {
      this.$router.go(-1); // 返回上一层
    },
    rowClassName({ row, rowIndex }) {
      if (row.errcode === 1) {
        return "col-dise";
      }
    },
    cellClassName({ row, rowIndex, column, columnIndex }) {
      if (columnIndex === 2 && row.errcode === 1) {
        // if (row.sex >= '1') {
        //   return 'col-red'
        // } else if (row.age === 26) {
        //   return 'col-orange'
        // }
        return "col-red";
      }
    },
    // 勾选后显示错误表数据
    showfalseData() {
      if (this.single === true) {
        this.tableData = this.errTableData;
      } else {
        this.tableData = this.finalTableData;
      }
    },
    // 导入后保存
    saveDev() {
      if (this.tableData.length === 0) {
        this.$message.error("当前暂无导入设备！");
      } else {
        // 判断当前是否存在错误数据
        if (this.errTableData.length > 0) {
          this.$message.error("存在错误数据,无法保存！");
        } else {
          this.$refs.xTable.validate((valid) => {
            if (!valid) {
              this.axios({
                url: "/iext/back/device/DeviceController/savedevicelist",
                method: "post",
                data: this.finalTableData
              })
                .then((res) => {
                  if (res.data.success === 1) {
                    this.$Message.success("操作成功！");
                    this.$router.push({
                      path: "/deviceManage/check"
                    });
                  } else {
                    this.$Message.error(res.data.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        }
      }
    },
    beforeUpload(file) {},
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc: "文件 " + file.name + " 格式不正确，请上传.xls,.xlsx文件。"
      });
    },
    handleSuccess(res, file) {
      if (res.success === 1) {
        this.dialoLead = false;
        this.$Message.success("数据导入成功！");
        // this._getBookList()
        this.$refs.upload.clearFiles();
      } else {
        this.$Message.error(res.msg);
      }
      this.tableData = res.body.devicelist;
      this.finalTableData = res.body.devicelist; // 最后提交的数据
      this.errcount = res.body.errcount;
      this.sumcount = res.body.sumcount;
      for (var i = 0; i < res.body.devicelist.length; i++) {
        if (res.body.devicelist[i].errcode === 1) {
          this.errTableData.push(res.body.devicelist[i]);
        }
      }
      this.hasImport = true;
    },
    handleError() {
      this.$Message.error("数据导入失败！");
    },
    // 下载模板
    downloadTemplete() {
      window.location.href = "/static/设备批量导入.xls";
    },
    // 导入
    inport() {},
    // 删除
    delect() {
      this.$refs.xTable.removeSelecteds();
      this.getRemoveEvent();
      this.errcount = this.errTableData.length;
      this.sumcount = this.finalTableData.length;
    },
    // 删除的数据
    getRemoveEvent() {
      const removeRecords = this.$refs.xTable.getRemoveRecords();
      if (removeRecords.length === 0) {
        this.$XModal.alert("请选择你要删除的数据");
      } else {
        if (this.single === false) {
          // for(var i =0;i<removeRecords.length;i++){
          //   for(var k =0;k<this.tableDate.length;k++){
          //     if(removeRecords[i] == this.tableDate[k]){
          //       this.tableDate.splice(k,1);
          //     }
          //   }
          // }
          if (this.errTableData.length > 0) {
            for (var i = 0; i < removeRecords.length; i++) {
              if (removeRecords[i].errcode === 1) {
                for (var k = 0; k < this.errTableData.length; k++) {
                  if (
                    removeRecords[i].aydNumber ===
                    this.errTableData[k].aydNumber
                  ) {
                    this.errTableData.splice(k, 1);
                  }
                }
              }
            }
          }

          for (let i = 0; i < removeRecords.length; i++) {
            for (let k = 0; k < this.finalTableData.length; k++) {
              if (
                removeRecords[i].aydNumber === this.finalTableData[k].aydNumber
              ) {
                this.finalTableData.splice(k, 1);
              }
            }
          }

          this.tableData = this.finalTableData;
        } else {
          //   for(var i =0;i<removeRecords.length;i++){
          //   for(var k =0;k<this.tableDate.length;k++){
          //     if(removeRecords[i] == this.tableDate[k]){
          //       this.tableDate.splice(k,1);
          //     }
          //   }
          // }

          for (let i = 0; i < removeRecords.length; i++) {
            for (let k = 0; k < this.finalTableData.length; k++) {
              if (
                removeRecords[i].aydNumber === this.finalTableData[k].aydNumber
              ) {
                this.finalTableData.splice(k, 1);
              }
            }
          }

          for (let i = 0; i < removeRecords.length; i++) {
            for (let k = 0; k < this.errTableData.length; k++) {
              if (
                removeRecords[i].aydNumber === this.errTableData[k].aydNumber
              ) {
                this.errTableData.splice(k, 1);
              }
            }
          }

          this.tableData = this.errTableData;
        }
      }
    }
  },
  created() {
    this.uploadExclUrl = "/iext/back/device/DeviceController/importdevicelist";
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.importDiv {
  width: 600px;
  height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFangSC;
  font-weight: 500;
  color: rgba(41, 128, 185, 1);
  line-height: 50px;
}
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-one {
    margin: 16px 0;
  }
  .select-two {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .st-block {
      height: 36px;
      line-height: 36px;
      display: flex;
      .st-label {
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        height: 36px;
      }
      .st-datepicker {
        width: 240px;
        height: 36px;
      }
    }
    .ml10 {
      margin-left: 10px;
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  .table-area {
    .devTable {
      .vxe-table--body-wrapper {
      }
      table {
        position: fixed !important;
      }
    }
    .devTable /deep/ .vxe-body--column.col-red {
      // background-color: red;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      color: rgba(208, 2, 27, 1);
    }
    .devTable /deep/ .vxe-body--row.col-dise {
      background-color: rgba(245, 34, 45, 0.1); //#F5222D;
    }
    .ivu-table-wrapper /deep/ .ivu-table {
      .ivu-table-body {
        position: fixed !important;
      }
    }
  }
}
.main-body .table-area .devTable /deep/ .vxe-table--header-wrapper {
  .vxe-table--header {
    .vxe-header--row th .vxe-cell .vxe-edit-icon {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.mainbody {
  .ivu-tabs-nav-container {
    /*background:rgba(228,235,241,1)*/
  }
  .el-tree-node__content {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid rgba(223, 227, 232, 1);
  }
}
</style>
